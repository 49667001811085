<template>
  <Card
    as="article"
    notch-size="big"
    notch-position="bottom"
    class="ProductCard lg:bg-transparent rounded-3xs lg:shadow-none lg:rounded-none px-5"
    :class="{
      'ProductCard__landscape grid md:flex md:flex-col ': landscape,
      'ProductCard__portrait flex flex-col': !landscape,
    }"
    @click="productClickEvent"
  >
    <ProductTags
      :is-new="!!product?.isNew"
      :price="product?.price"
      :price-before="product?.priceBefore"
      class="[ absolute top-7 left-7 ]"
    />

    <FavoriteButton
      :sku="productInternal.sku"
      class="[ absolute top-6 right-6 w-8 h-7 ] z-10 text-primary-700"
      @unfavorite="emits('wishlist-removed', undefined)"
    />

    <AppLink
      :to="`/${product.slug}`"
      class="pt-16 relative bg-white flex items-center justify-center overflow-hidden rounded-3xs lg:rounded-t-4xs"
    >
      <AppImage
        :src="product.thumb.src"
        :alt="product.thumb.alt"
        class="ProductCard__Thumb object-contain"
        :class="{ ProductCard__Thumb__landscape: landscape, ProductCard__Thumb__portrait: !landscape }"
        loading="lazy"
        width="200"
        height="200"
      />
    </AppLink>

    <div class="ProductCard__Details flex lg:grid lg:flex-1 flex-col py-5 lg:p-0 lg:mt-5 justify-around">
      <div v-if="product?.brandImage" class="flex flex-col hover:underline">
        <AppImage
          :src="product?.brandImage"
          class="h-7 w-fit object-contain"
          alt="brand logo"
          loading="lazy"
          width="64"
          height="32"
        />
      </div>
      <div v-else></div>

      <AppLink class="flex flex-col hover:underline" :to="`/${product.slug}`">
        <p class="text-base text-primary-A900 line-clamp-3 text-left">{{ product.name }}</p>
      </AppLink>

      <div v-if="!product.isCommercial" class="flex lg:items-baseline flex-col">
        <Money
          class="text-lg font-bold text-primary-700"
          :value="product.price"
          :fallback="$t('priceUnavailable')"
          rounded
        />
        <Money
          v-if="product.priceBefore && product.price < product.priceBefore"
          :value="product.priceBefore"
          class="text-primary-200 line-through"
          rounded
        />
        <div class="h-12 text-left">
          <span v-if="isLimitedStock" class="text-red-700 font-light">
            {{ $t('limitedQty', { count: product.stock }) }}
          </span>
          <span v-else-if="!product.stock" class="text-red-700">
            {{ $t('outOfStock') }}
          </span>
        </div>
      </div>

      <button
        type="button"
        class="[ h-12 w-12 rounded-2xs ] [ flex items-center justify-center ] [ absolute bottom-0 right-0 ] bg-coloration-secondary"
        :aria-label="$t('compareName', { name: product.name })"
        @click="toggleComparison"
      >
        <svg-icon :name="isInComparison ? 'check' : 'compare'" class="w-7 h-7 text-white"></svg-icon>
      </button>
    </div>
  </Card>
</template>

<script setup lang="ts">
import { clone } from 'lodash-es';
const { appCompareItem } = useCompareItem();
const props = withDefaults(
  defineProps<{
    product: ProductNode;
    landscape?: boolean;
    nameOnly?: boolean;
  }>(),
  {
    landscape: false,
    nameOnly: false,
  },
);
const { limited_qty_threshold } = useStoreConfig();

const emits = defineEmits<{
  (e: 'wishlist-removed', code: undefined): void;
}>();
// const el = ref<Element>();
const observer = ref<IntersectionObserver>();
const { productClick } = useGtmEvents();
/**
 * component state
 */

const isLimitedStock = computed(() => {
  return props.product?.stock && (limited_qty_threshold.value || Number.MIN_SAFE_INTEGER) >= props.product?.stock;
});

const isInComparison = useIsInComparison(props.product?.sku || '');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function toggleComparison() {
  if (isInComparison.value) {
    removeItem(props.product?.sku || '');
    return;
  }

  appCompareItem({
    sku: props.product?.sku || '',
    categories: props.product?.categories?.map(c => c?.id || 0) || [],
  });
}

const productInternal = ref(clone(props.product));

// onMounted(() => {
//   // Send product impression event only if card is above the fold
//   observer.value = new IntersectionObserver(
//     entries => {
//       if (entries[0].intersectionRatio === 1) {
//         observer.value?.disconnect();
//       }
//     },
//     {
//       threshold: [1.0],
//     },
//   );

//   if (el.value) observer.value.observe(el.value);
// });

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

/**
 * Function that handles triggering product_click event for Enhanced Ecommerce
 */
function productClickEvent() {
  productClick(props.product);
}

const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style lang="postcss" scoped>
.ProductCard__landscape {
  grid-template-columns: 96px 1fr 30px;
  grid-auto-rows: 228px;
  column-gap: 10px;
}

.ProductCard {
  @apply relative cursor-pointer h-full;

  border-radius: 40px;
  z-index: 1;

  @screen lg {
    width: 100%;
  }

  &__Thumb__landscape {
    width: 96px;
    height: 96px;

    @screen lg {
      width: 200px;
      height: 151px;
    }
    @apply transition-transform duration-300 ease-in-out transform;
  }

  &__Thumb__portrait {
    width: 200px;
    height: 150px;

    @screen lg {
      width: 200px;
      height: 151px;
    }
    @apply transition-transform duration-300 ease-in-out transform;
  }

  &__Details {
    @screen lg {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 38px 72px 1fr;
    }
  }

  &:hover {
    .ProductCard__Thumb {
      @apply scale-110;
    }
  }

  &__compare {
    transition:
      transform 0.2s ease-in-out,
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.fade-out {
  transition: opacity 0.4s ease-in;
}
.fade-in {
  transition: opacity 0.4s ease-out;
}

ul.dashed > li:before {
  content: '-';
  text-indent: -5px;
}
ul.dashed > li {
  text-indent: -5px;
}

.card-container {
  filter: drop-shadow(9px 3px 15px rgba(21, 44, 115, 0.15));
}
</style>

<i18n>
{
  "en": {
    "off": "Off",
    "specialOffer": "Special Offer",
    "startsFrom": "Starts from",
    "limitedQty":"Only {count} Left",
    "outOfStock": "Out of Stock",
    "compare": "Compare Product",
    "goToOptions": "Multiple Options Available",
    "isisNew": "New-in",
    "addToCart": "Add To Cart",
    "rating": " {rating}/5",
    "notFound": "NA",
    "priceUnavailable": "Price Unavailable",
    "price": "Price",
    "priceBefore": "Price Before",
    "brand": "Brand",
    "description": "Description",
    "stock": "Stock",
    "compareName": "Compare {name}"
  },
  "ar": {
    "off": "خصم",
    "specialOffer": "عرض خاص",
    "startsFrom": "يبدأ من",
    "limitedQty":"كمية محدودة",
    "outOfStock": "نفذت الكمية",
    "compare": "مقارنه المنتج",
    "goToOptions": "الخيارات",
    "isNew": "جديد",
    "addToCart": "أضف الى السله",
    "notFound": "غير موجود",
    "rating": " {rating}/5",
    "priceUnavailable": "السعر غير متوفر",
    "price": "السعر",
    "priceBefore": "السعر القديم",
    "brand": "العلامة التجارية",
    "description": "الوصف",
    "stock": "المخزون",
    "compareName": "مقارنة {name}"
  }
}
</i18n>
