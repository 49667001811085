<template>
  <button
    data-cy="add-to-wishlist"
    title="Add to wishlist"
    type="button"
    class="ProductCard__favorite [ flex items-center justify-center ]"
    :class="{
      'bg-red-200 rounded-[18px] p-2': type === 'product-page',
    }"
    @click="toggleWishlist"
  >
    <svg-icon
      v-if="type === 'product-card'"
      :name="isFavorited ? 'favorite-red' : 'favorite-dimmed'"
      class="w-full h-full"
    />
    <svg-icon
      v-if="type === 'product-page'"
      :name="isFavorited ? 'product-page-favorite-red' : 'product-page-favorite-dimmed'"
      class="w-full h-full"
    />
    <slot />
  </button>
</template>

<script setup lang="ts">
import type { ProductData } from '@robustastudio/e-commerce/common';
import { debounce } from 'lodash-es';

const props = defineProps({
  sku: {
    type: String,
    required: true,
  },
  product: {
    type: Object as PropType<ProductData>,
    required: false,
    default: () => ({}),
  },
  type: {
    type: String as PropType<'product-card' | 'product-page'>,
    default: 'product-card',
  },
});
const isFavorited = ref(false);
// const { emit: emitEvent } = useEventBus();

const isInWishlist = useIsInWishlist(props.sku);

watch(
  isInWishlist,
  value => {
    isFavorited.value = value;
  },
  {
    immediate: true,
  },
);
const emit = defineEmits(['unfavorite']);

const { addToWishlist } = useAddToWishlist(props.sku);
const { removeFromWishlist } = useRemoveFromWishlist(props.sku);
// Ensures we don't call too many requests
const commitToggle = debounce(() => {
  if (isFavorited.value) {
    // props.product && emitEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, props.product);

    return addToWishlist();
  }

  return removeFromWishlist().then(() => {
    emit('unfavorite');
  });
}, 500);

function toggleWishlist() {
  isFavorited.value = !isFavorited.value;

  commitToggle();
}
</script>

<style lang="postcss" scoped>
.ProductCard__favorite {
  @apply flex items-center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }

  svg.is-active path {
    @apply fill-current text-red-700;
  }
}
</style>
